<template>
	<el-dialog title="" :visible.sync="createdVisible" width="578px" center :close-on-click-modal="false" @close="onResetForm('ruleForm')">
		<div slot="title" class="postion">
			<i class="iconfont icon-bianji1"></i>
			<span class="download">查看账户</span>
		</div>
		<el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<el-form-item label="账户名称" prop="displayName">
				<el-input v-model.trim="ruleForm.displayName" disabled placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item class="addressClass clearfix" label="业务区域" prop="regionCode">
				<el-cascader
					v-if="editRowVisible"
					:options="options"
					:props="props"
					disabled
					clearable
					v-model="ruleForm.regionCode"
					ref="bussniessCascader"
				></el-cascader>
			</el-form-item>
			<el-form-item class="w50 mr16" prop="principalName" label="负责人">
				<el-input v-model.trim="ruleForm.principalName" disabled placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" disabled maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item prop="roles" label="数据权限" v-if="ruleForm.regionCode.length == 3">
				<el-checkbox-group v-model="ruleForm.roles" disabled>
					<el-checkbox label="view">数据查看</el-checkbox>
					<el-checkbox label="crud">功能操作</el-checkbox>
				</el-checkbox-group>
			</el-form-item>
			<el-form-item label="备注" prop="description">
				<el-input type="textarea" disabled v-model.trim="ruleForm.description" autosize></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary">确 定</el-button>
		</span>
	</el-dialog>
</template>

<script>
export default {
	data() {
		return {
			createdVisible: false,
			ruleForm: {
				displayName: '',
				accountLevel: '',
				regionCode: '',
				principalName: '',
				phoneNumber: '',
				roles: [],
				description: '',
			},
			props: {
				value: 'code',
				label: 'title',
				children: 'children',
				checkStrictly: true,
				lazy: true,
				lazyLoad: (node, resolve) => {
					const { level, value } = node;
					console.log(level, value, node);
					this.getArea(level, value, resolve);
				},
			},
			options: [],
			editRowVisible: false,
		};
	},
	components: {},
	props: ['isShowDetail'],
	methods: {
		init(data) {
			this.createdVisible = true;
			this.editRowVisible = false; //手动重新渲染级联组件
			if (data) {
				setTimeout(() => {
					this.editRowVisible = true;
				}, 1);
				this.$nextTick(() => {
					this.ruleForm = { ...data } || {};
					this.ruleForm.regionCode = data.chinaRegionDtoList
						? data.chinaRegionDtoList.filter((item) => item && item.code).map((v) => v.code)
						: data.regionCode == '0'
						? ['0']
						: [];
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
		},
		// 获取地区
		getArea(level, value, resolve) {
			let code = value ? value : null;
			this.$http
				.get(this.api['ChinaRegions#index'].href, { params: { code } })
				.then((res) => {
					if (res.data && res.data.success) {
						let nodes = res.data.collection || [];
						if (!value && level == 0) {
							nodes.unshift({
								code: '0',
								title: '全国',
								children: null,
								leaf: false,
							});
						}
						if (level == 4) {
							nodes.forEach((item) => {
								// 当点击最后一级的时候 label 后面不会转圈圈 并把相关值赋值到选择器上
								item.leaf = level >= 1;
							});
						}
						//通过调用resolve将子节点数据返回，通知组件数据加载完成
						resolve(nodes);
					}
				})
				.catch((e) => {});
		},

		onResetForm(formName) {
			this.createdVisible = false;
			this.$refs[formName].resetFields();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	padding-bottom: 20px;
	> div {
		margin-bottom: 20px !important;
		.el-form-item__label {
			line-height: 14px;
			height: 14px;
			margin-bottom: 8px;
		}
	}
	.el-cascader,
	.el-input,
	.el-select {
		width: 100%;
	}
	.dateClass {
		.el-range-editor {
			width: 100%;
		}
		.el-range-input {
			width: 45%;
		}
		.el-range-separator {
			display: inline-block;
			line-height: 32px;
			height: 32px;
		}
	}
}
.addressClass {
	&-content {
		width: calc((100% - 32px) / 5);
		margin-right: 8px;
		margin-bottom: 0;
	}
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .el-dialog__footer {
	padding-top: 0px !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>